<template>
  <v-container class="container-card">
    <v-card>
      <NewTabelaCampanhas cadastro action ref="consultaCampanha">
        <template v-slot:[`item.nome_campanha`]="{ item }">
          <template v-if="Number(item.total_empresas) > 0">
            <v-btn text plain @click="empresasParticipantes(item)">
              <v-badge
                v-if="Number(item.total_empresas) > 1"
                bordered
                :content="'+' + (Number(item.total_empresas))"
              >
                {{ item.nome_campanha | TitleCase }} - {{ item.apelido }}
              </v-badge>
              <template v-else>
                {{ item.nome_campanha | TitleCase }} - {{ item.apelido }}
              </template>
            </v-btn>
          </template>
          <template v-else>
            Não há empresas vinculadas
          </template>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            v-can-access="92"
            @click="editarCampanha(item)"
            :disabled="disableAction(item.id_status_camp)"
            color="warning"
            small
          >
            <v-icon color="warning">mdi-pencil </v-icon>
          </v-btn>

          <v-btn
            icon
            v-can-access="24"
            class="ml-2"
            @click="aprovarCampanha(item)"
            :disabled="item.status == 'N'"
            color="primary"
            small
          >
            <v-icon color="primary">mdi-eye </v-icon>
          </v-btn>
          <v-btn
            icon
            v-can-access="421"
            @click="confirmDeleteItem(item)"
            :disabled="disableAction(item.id_status_camp)"
            small
          >
            <v-icon color="red">mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </NewTabelaCampanhas>
    </v-card>
    <ModalEmpresasCampanha
      :dialog="dialog"
      :empresas="listaEpresas"
      deleteItem
      @close="dialog = false"
    />
  </v-container>
</template>

<script>
import NewTabelaCampanhas from "@/components/campanha/NewTabelaCampanhas";
import ModalEmpresasCampanha from "@/components/campanha/ModalEmpresasCampanha";
import serviceCampanha from "@/services/campanha";
import serviceEmpresa from "@/services/empresa";
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "ConsultaCampanha",

  components: {
    NewTabelaCampanhas,
    ModalEmpresasCampanha
  },

  data() {
    return {
      listaEpresas: [],
      dialog: false
    };
  },

  computed: {
    ...mapGetters({
      getUser: "getUser"
    })
  },

  methods: {
    ...mapMutations({
      setCurrentCampanha: "campanha/setCurrentCampanha"
    }),

    ...mapActions({
      fetchCampanhas: "campanha/fetchCampanhas",
      fetchTest: "campanha/fetchTest",
      fetchView: "sistema/fetchView"
    }),

    editarCampanha(item) {
      //this.setCurrentCampanha(item.id_campanha);
      this.$router.push("cadastro/" + item.id_campanha);
    },

    aprovarCampanha(item) {
      this.$router.push(`aprovacao/${item.id_campanha}`);
    },

    async empresasParticipantes(item) {
      try {
        const idCampanha = item.id_campanha;
        const empresasCampanhas = await serviceCampanha.getEmpresasParticipantes(
          {
            id_campanha: idCampanha
          }
        );
        const empresas = empresasCampanhas.data.data
          .map(item => item.id_empresa)
          .toString();

        const {
          data: { data }
        } = await serviceEmpresa.getEmpresas({ id_empresa: empresas });

        this.listaEpresas = data;
        this.dialog = true;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Não foi possivel buscar as empresas"
        });
      }
    },

    async confirmDeleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deletar Campanha",
          `Deseja deletar a campanha <br> ${item.nome_campanha}?`
        );
        await this.deleteItem(item);
      } catch (error) {
        console.log("Deleção cancelada");
      }
    },

    async deleteItem(item) {
      try {
        await serviceCampanha.deleteCampanha(item.id_campanha);
        this.$notify({
          type: "success",
          text: "Campanha deletada"
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar campanha"
        });
      } finally {
        this.$refs.consultaCampanha.fetchCampanhas({
          ano_ref: item.ano_ref,
          mes_ref: item.mes_ref
        });
      }
    },

    disableAction(status) {
      if (this.getUser.nivel == "1") {
        return false;
      }
      switch (status) {
        case "1":
          return true;

        case "21":
          return true;

        case "22":
          return true;
      }
    }
  },

  mounted() {
    this.fetchView("Consulta Campanha");
  }
};
</script>
