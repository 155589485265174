<template>
  <v-btn text :loading="loadingBtn" @click="dowloadXlsx()" :disabled="disabled">
    <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
    {{ label }}
  </v-btn>
</template>

<script>
import campanhaService from "@/services/campanha";
import { mapGetters } from "vuex";

export default {
  name: "RelatorioXlsx",

  props: {
    filter: {
      type: Object
    },
    meta: {
      type: Boolean,
      default: false
    },
    realizado: {
      type: Boolean,
      default: false
    },
    apuracaoGeral: {
      type: Boolean,
      default: false
    },
    apuracaoInfinite: {
      type: Boolean,
      default: false
    },
    consultaCampanha: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    fileName: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loadingBtn: false
    };
  },

  computed: {
    ...mapGetters({
      getCurrentCampanha: "campanha/getCurrentCampanha"
    })
  },

  methods: {
    async dowloadXlsx() {
      try {
        this.loadingBtn = true;

        let filter = {};
        for (const key in this.filter) {
          if (this.filter[key]) {
            filter[key] = this.filter[key];
          }
        }

        let response;
        if (this.meta) {
          response = await campanhaService.getMetasCampanhaXls({
            ...filter,
            id_campanha: this.getCurrentCampanha
          });
        } else if (this.realizado) {
          response = await campanhaService.getRealizadosCampanhaXls({
            ...filter,
            id_campanha: this.getCurrentCampanha
          });
        } else if (this.apuracaoGeral) {
          response = await campanhaService.getApuracaoGeralCampanhaXls(filter);
        } else if (this.apuracaoInfinite) {
          response = await campanhaService.getApuracaoInfiniteCampanhaXls(
            filter
          );
        } else if (this.consultaCampanha) {
          response = await campanhaService.getConsultaCampanhaXls(filter);
        }

        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
          encoding: "UTF-8"
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          (this.fileName || this.$route.params.nomeCampanha) + ".xlsx";
        link.click();
      } catch (error) {
        console.log(error);
        this.$notify({ type: "error", text: "Erro ao efetuar download" });
      } finally {
        this.loadingBtn = false;
      }
    }
  }
};
</script>
