var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"320","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune-variant")]),_vm._v(" Filtro ")],1)]}}])},[_c('v-card',[_c('v-container',[_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-domain","clearable":"","single-line":"","label":"Empresa","item-text":"apelido","item-value":"apelido","service":"sistemaService.empresa","filters":{
          calcula_comissao: 'S'
        },"isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.apelido),callback:function ($$v) {_vm.$set(_vm.filters, "apelido", $$v)},expression:"filters.apelido"}}),_c('DatePickerMonth',{attrs:{"chips":"","currentDate":false,"dateValue":_vm.getStorage()},on:{"date":function($event){return _vm.assignDateFilter($event)}}}),_c('v-autocomplete',{attrs:{"prepend-inner-icon":"mdi-list-status","label":"Status","single-line":"","items":_vm.status.items,"item-text":"text","item-value":"id_status_camp","clearable":""},on:{"change":function($event){return _vm.handleFilter()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.text)+" ")],1):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.statusCampanha.length - 1)+") "),_c('br')]):_vm._e()]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.filters.id_status_camp),callback:function ($$v) {_vm.$set(_vm.filters, "id_status_camp", $$v)},expression:"filters.id_status_camp"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }