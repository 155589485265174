var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"mt-4 table-shadow",attrs:{"headers":_vm.headers,"items":_vm.campanhas.data,"search":_vm.search,"sort-by":"data_cad","sort-desc":true,"loading":_vm.loading,"paginate":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Campanhas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.cadastro)?_c('v-btn',{directives:[{name:"can-access",rawName:"v-can-access",value:(91),expression:"91"}],attrs:{"text":"","to":{ name: 'cadastro-capa-campanha' }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v(" Cadastrar ")],1):_vm._e(),_c('RelatorioXlsx',{attrs:{"consultaCampanha":"","fileName":"campanhas","filter":_vm.filters,"label":"Relatório"}}),_c('FilterCampaign',{on:{"selectedFilters":function($event){_vm.fetchCampanhas((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.valor_custo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_custo))+" ")]}},{key:"item.nome_campanha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.nome_campanha))+" ")]}},{key:"item.perc_crescimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.perc_crescimento)+"% ")]}},{key:"item.data_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_inicio))+" - "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_final))+" ")]}},{key:"item.data_cad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_cad))+" ")]}},{key:"item.usercrioucampanha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.usercrioucampanha))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'S')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):(item.status == 'N')?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")]):_c('v-icon',{attrs:{"color":"gray"}},[_vm._v(" mdi-dots-horizontal ")])]}},{key:"item.id_status_camp",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.statusCampanha(item.id_status_camp).color}},[_vm._v(_vm._s(_vm.statusCampanha(item.id_status_camp).icon))])]}},_vm._l((_vm.$scopedSlots),function(_,scopedSlotName){return {key:scopedSlotName,fn:function(slotData){return [_vm._t(scopedSlotName,null,null,slotData)]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }